.screen_wrapper {
    display: grid;
    grid-auto-rows: 10% 90%;
    height: 100%;
    width: 100%;
}

.project_btn {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.btn_create svg {
    width: 3rem;
    height: 3rem;
    color: var(--primary-color);
    cursor: pointer;
}

.dashboard_message {
    text-align: center;
    font-size: 24px;
    color: var;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

